import React, { useState, useEffect, MouseEvent, useRef } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
//import Collapse from "react-bootstrap/Collapse";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BRFlag from "../../../assets/icons/brazil.svg";
import ESFlag from "../../../assets/icons/spain.svg";
import ENFlag from "../../../assets/icons/united-kingdom.svg";
//import iconUser from "../../../assets/icons/fa-user.svg";
//import iconBarsLarge from '../../../assets/icons/fa-bars-large.svg';
//import menu from "../../../assets/icons/menu.svg";

import CartHeader from "../../Cart/CartHeader";

import "./NavHeaderCaracol.scss";
import AuthLogin from "../../Authenticated/AuthLogin/AuthLogin";
import MenuSideBarCaracol from "./MenuSidebarCaracol/MenuSideBarCaracol";

const NavHeaderCaracol = () => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [activeFlag] = useState<any>({ pt: BRFlag, en: ENFlag, es: ESFlag });
    //const [modalShow, setModalShow] = useState(false);
    const [lng, setLng] = useState(i18next.language);
    //const [loading, setLoading] = useState<any>(false);

    const changeLanguage = (e: MouseEvent<HTMLButtonElement>) => {
        var element = (e.target as HTMLButtonElement).value;
        setLng(element);
        i18next.changeLanguage(element);
    };

    const auth = localStorage.getItem('bpasiteAuth') || '{}';

    function logOut() {
        localStorage.removeItem('bpasiteAuth');
        window.location.reload();
    }

    /* Detecta clique fora da div#wrapper para fechar o dropdown de login */
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    var elem: any = document.getElementById("authLoginDropdown");
                    if (elem) {
                        elem.classList.remove('active');
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    /* //Detecta clique fora da div#wrapper para fechar o dropdown de login\\ */

    function keepDropdownOpen() {
        var elem: any = document.getElementById("authLoginDropdown");

        if (elem && !elem.classList.contains('active')) {
            elem.classList.add('active');
        }
    }

    return (
        <nav className="navbar navbar-expand navbar-light bg-primary">
            <div className="container-md flex-md-row" style={{ maxHeight: "90px" }}>

                {/* Logo Site */}
                <Link to="/" className="navbar-brand text-center fw-bold text-light" style={{ marginRight: "0" }}>
                    <img alt="Logo do Caracol" className="img-fluid logo-caracol-header" src={process.env.REACT_APP_SERVER_LOGO}></img>
                </Link>
                {/* Logo Site */}

               
                {/* Flag Dropdown */}
                <div
                    className="navbar-collapse d-none d-md-flex"
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav ml-auto" style={{ maxHeight: "80px", alignItems: "center" }}>
                        {/*                         <li className="nav-item d-flex align-items-center">
                            <a
                                href={
                                    window.location.origin + "#buy-product"
                                }
                            >
                                <button className="buy-tickets-cta">COMPRAR BILHETES</button>
                            </a>
                        </li> */}
                        <li className="nav-item dropdown nav-item-c2" id="wrapper" ref={wrapperRef}>
                            <Link
                                className="nav-link text-primary text-center "
                                to="#"
                                id="navbarDropdownLang"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <img
                                    alt={
                                        lng === "pt"
                                            ?
                                            "Bandeira nacional do Brasil"
                                            :
                                            lng === "en"
                                                ?
                                                "Bandeira nacional do Reino Unido"
                                                :
                                                lng === "es"
                                                    ?
                                                    "Bandeira nacional da Espanha"
                                                    :
                                                    "Bandeira nacional do Brasil"
                                    }
                                    src={activeFlag[lng] === undefined ? activeFlag.pt : activeFlag[lng]}
                                    style={{ width: "45px", height: "30px", display: "inline" }}
                                />
                                <span className="language-selected">
                                    {
                                        lng === "pt"
                                            ?
                                            t("navHeader.language.portugues")
                                            :
                                            lng === "en"
                                                ?
                                                t("navHeader.language.ingles")
                                                :
                                                lng === "es"
                                                    ?
                                                    t("navHeader.language.espanhol")
                                                    :
                                                    t("navHeader.language.portugues")
                                    }
                                </span>
                            </Link>

                            <div
                                className="dropdown-menu languages px-4 py-3 rounded"
                                aria-labelledby="navbarDropdownLang"
                                data-dropdown="navbarDropdownMinhaConta"
                                style={{ zIndex: 1040 }}
                            >
                                <button
                                    className={lng === "pt" ? "pt active" : "pt"}
                                    onClick={changeLanguage}
                                    value="pt"
                                >
                                    <img
                                        id="port-lang"
                                        alt="Bandeira nacional do Brasil"
                                        src={BRFlag}
                                        style={{ width: "45px", height: "30px" }}
                                    />
                                    {t("navHeader.language.portugues")}
                                </button>
                                <button
                                    className={lng === "en" ? "en active" : "en"}
                                    onClick={changeLanguage}
                                    value="en"
                                >
                                    <img
                                        id="esp-lang"
                                        alt="Bandeira nacional do Reino Unido"
                                        src={ENFlag}
                                        style={{ width: "45px", height: "30px" }}
                                    />
                                    {t("navHeader.language.ingles")}
                                </button>
                                <button
                                    className={lng === "es" ? "es active" : "es"}
                                    onClick={changeLanguage}
                                    value="es"
                                >
                                    <img
                                        id="ingl-lang"
                                        alt="Bandeira nacional da Espanha"
                                        src={ESFlag}
                                        style={{ width: "45px", height: "30px" }}
                                    />
                                    {t("navHeader.language.espanhol")}
                                </button>
                            </div>
                        </li>

                        <li className="nav-item active nav-item-c2 d-none">
                            <Link className="nav-link text-primary text-center" to="/">
                                <i className="fal fa-home fa-lg" aria-hidden="true"></i>
                            </Link>
                        </li>

                        {/* Flag Dropdown */}

                        {/* User Dropdown */}
                        <li
                            className="nav-item dropdown nav-item-c2"
                            data-nav="conta"
                            onClick={keepDropdownOpen}
                        >
                            {auth === "{}" || auth === 'null' ?
                                <>
                                    <Link
                                        className="nav-link text-primary text-center"
                                        to="#"
                                        id="navbarDropdownMinhaConta"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <FontAwesomeIcon
                                            className='keep-color'
                                            icon={["fal", "user"]}
                                            size="2x"
                                            style={{ color: "#ffffff", width: "20px" }}
                                        />
                                    </Link>

                                    <div
                                        className="dropdown-menu my-account px-4 py-3 rounded"
                                        id="authLoginDropdown"
                                        aria-labelledby="navbarDropdownMinhaConta"
                                        data-dropdown="navbarDropdownMinhaConta"
                                        style={{ zIndex: 1040 }}
                                    >
                                        {/* <h4 className="text-primary">{t("navHeader.login.title")}</h4> */}
                                        <h4 className="text-primary mb-3">{t("navHeader.login.subTitle")}</h4>
                                        <AuthLogin />
                                    </div>
                                </>

                                : <>
                                    <Link
                                        className="nav-link text-primary text-center"
                                        to="#"
                                        id="navbarDropdownMinhaConta"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        {
                                            JSON.parse(auth).userModel.photo === null
                                                ?
                                                <FontAwesomeIcon
                                                    className='keep-color'
                                                    icon={['fad', 'user-circle']}
                                                    size="2x"
                                                    style={{ color: "white" }}
                                                />
                                                :
                                                <i>
                                                    {/* <div className="loggedIn-profile-pic" style={{ backgroundImage: `url(${JSON.parse(auth).userModel.photo})` }}></div> */}
                                                    <img
                                                        src={JSON.parse(auth).userModel.photo}
                                                        className="loggedIn-profile-pic"
                                                        alt="icon-user"
                                                        referrerPolicy="no-referrer"
                                                    />
                                                </i>
                                        }
                                    </Link>

                                    <div
                                        className="dropdown-menu my-account logged-in px-4 py-3 rounded"
                                        aria-labelledby="navbarDropdownMinhaConta"
                                        data-dropdown="navbarDropdownMinhaConta"
                                        style={{ zIndex: 1040 }}
                                    >
                                        <Link
                                            to="/perfil"
                                            style={{ display: "block", color: "black", textDecoration: "none" }}
                                        >
                                            {t("navHeader.login.title")}
                                        </Link>
                                        <Link
                                            to="/minhas-atividades"
                                            style={{ display: "block", color: "black", textDecoration: "none" }}
                                        >
                                            {t("navHeader.login.myActivities")}
                                        </Link>
                                        <Link
                                            to="/"
                                            style={{ display: "block", color: "black", textDecoration: "none" }}
                                            onClick={logOut}
                                        >
                                            {t("navHeader.login.leave")}
                                        </Link>

                                    </div>
                                </>}

                        </li>
                    </ul>
                </div>
            </div>
            
        </nav>
    );
};

export default NavHeaderCaracol;
