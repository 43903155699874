import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import api from "../../services/api";
import configData from "../../config/config.json";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Calendar.scss";
import CalendarMessage from "../CalendarMessage/CalendarMessage";

const weekDays = i18next.language === "en" ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] : i18next.language === "es" ? ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"] : ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];

const months = i18next.language === "en" ? ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"] : i18next.language === "es" ? ["Enero", "Febrero", "Marzo", "April", "Mayo", "Junio", "July", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"] : ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];


const numberMonths = 1;

export interface productInfo {
    modalityID: any;
    product: any;
    productCode: any;
    changePriceDateTour: any;
    index: any;
    dateTour: any;
    setRanges?: any;
    isIntegration?: any;
    setIsIntegration?: any;
}

let auxRanges: any = [];

const CustomMultipleInput = ({ openCalendar, value, modalityID, valueCalendar, setNewGetDate }: any) => {
    const { t } = useTranslation();

    const [arrow, setArrow] = useState<any>(false);

    function getArrowLimit() {
        var today: any = new Date();
        var monthControl = (today.getMonth() + 1);

        let timer1 = setTimeout(() => {

            var teste: any = document.getElementsByClassName('rmdp-arrow-container');
            console.log(teste)

            if (teste.length >= 1) {
                teste[0].addEventListener("click", (e: any) => {
                    monthControl = monthControl - 1;
                    setNewGetDate(monthControl);
                }, false)

                teste[1].addEventListener("click", (e: any) => {
                    monthControl = monthControl + 1;
                    setNewGetDate(monthControl);
                }, false)
            }
        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }

    function setTranslate() {
        document.querySelectorAll('.rmdp-week-day').forEach((item) => {
            item.setAttribute("translate", "no");
        })
    }

    useEffect(() => {
        let arrowBody = document.querySelector('body');
        if (arrowBody?.className === "modal-open") {
            setArrow(true);
        } else {
            setArrow(false);
        }

    }, []);

    const onChangeInput = () => {
        return true;
    }

    return (
        <div className="d-flex">
            <FontAwesomeIcon
                icon={["fal", "calendar-alt"]}
                size="1x"
                style={{ color: "#000", position: "absolute", transform: "translate(10px, 10px)" }}
            />
            <input
                className="form-control rounded-0 bg-white py-2 rounded calendar-input"
                onFocus={() => { openCalendar(); getArrowLimit(); setTranslate(); }}
                value={value}
                data-object={JSON.stringify(valueCalendar)}
                onChange={onChangeInput}
                readOnly
                id={`date-${modalityID}`}
                placeholder={t("productOptions.when")}
            />
            {
                arrow === true
                    ?
                    <FontAwesomeIcon icon={["fal", "chevron-down"]} size="1x" style={{ marginRight: "10px", color: "#707070" }} />
                    :
                    ""
            }
        </div>
    );
};

const DoubleCalendarTicket: React.FC<productInfo> = ({
    modalityID,
    product,
    productCode,
    changePriceDateTour,
    dateTour,
    index,
    setRanges,
    isIntegration,
    setIsIntegration
}: productInfo) => {
    const url = window.location.href;
    const codeUrl = url.split("/");
    const isTrilha = codeUrl[3] === "compra-rapida" ? codeUrl[4].split("?")[2] : codeUrl[4].split("?")[1];

    const [dayProduct, setDayProduct] = React.useState<any>({});
    const [hoursProduct, setHoursProduct] = React.useState<any>();
    const [valueCalendar, setValueCalendar] = useState<any>({});
    const [newGetDate, setNewGetDate] = React.useState<any>(null);

    const [auxTarif, setAuxTarif] = useState<any>(null);

    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : "BR";

    function handleDateTour(objCalendar: any) {
        let auxTar: any = [];

        for (let i = 0; i < auxTarif.length; i++) {
            if (auxTarif[i].selectedDate.split("T")[0] === objCalendar.data) {
                auxTar.push(auxTarif[i])
            }
        }

        var aux: any = dateTour;
        aux[index].data = objCalendar.data;
        aux[index].idSellingType = product.sellingType;
        aux[index].daysUniqueTarCode = objCalendar.daysUniqueTarCode;
        aux[index].prodModUniqueCode = objCalendar.prodModUniqueCode;
        aux[index].tarUniqueCode = objCalendar.tarUniqueCode;
        aux[index].adultText = objCalendar.adultText;
        aux[index].childText = objCalendar.childText;
        aux[index].infantText = objCalendar.infantText;
        aux[index].eldersText = objCalendar.eldersText;
        aux[index].studentText = objCalendar.studentText;
        aux[index].globalText = objCalendar.globalText;
        
        aux[index].auxTarif = auxTar;

        if (isIntegration === true) {
            aux[index].adult = objCalendar.priceAdultFinal;
            aux[index].child = objCalendar.priceChildFinal;
            aux[index].infant = objCalendar.priceInfantFinal;
            aux[index].student = objCalendar.priceStudentFinal;
            aux[index].elders = objCalendar.priceEldersFinal;
            aux[index].global = objCalendar.priceGlobalFinal;
        }

        changePriceDateTour(aux, hoursProduct);
    }

    useEffect(() => {
        let aux: any = [];
        let idChannel: number = 1;
        
        async function getDays(info: any) {
            
            var today: any;
            var todayDay: any;
            var todayMonth: any;
            var todayYear: any;
            var endMonth: any;
            var endYear: any;

            if (newGetDate === null) {
                today = new Date();
                todayDay = today.getDate() < 28 ? today.getDate() : 28;
                todayMonth = (today.getMonth() + 1);
                todayYear = today.getFullYear();
                endMonth = (today.getMonth() + 7);
                endYear = today.getFullYear();

                if (endMonth > 11) {
                    endMonth = endMonth - 11;
                    endYear = parseInt(endYear) + 1;
                }
            } else {
                today = new Date();
                todayDay = today.getDate() < 28 ? today.getDate() : 28;
                todayMonth = newGetDate - 1;
                todayYear = today.getFullYear();
                endMonth = newGetDate + 3;
                endYear = today.getFullYear();

                if (todayMonth > 12) {
                    todayMonth = todayMonth - 12;
                    todayYear = parseInt(todayYear) + 1;
                }
                if (endMonth > 12) {
                    endMonth = endMonth - 12;
                    endYear = parseInt(endYear) + 1;
                }
            }

            try {
                const { data } = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetCalendarTicketAsync`,
                    {
                        dateStart: todayYear + '-' + todayMonth + '-' + todayDay,
                        dateEnd: endYear + '-' + endMonth + '-' + todayDay,
                        productCode: `${productCode}`,
                        tarCode: `${info.tarCode}`,
                        ProdModCode: `${product.prodModCode}`,
                        lang: lng,
                        idCanal: isTrilha === "trilha" ? 7 : idChannel
                    }
                );
                if (data.status !== 400) {
                    setHoursProduct(data.data.activities);
                    setIsIntegration(data.data.isIntegrationSystem);

                    if(window.location.pathname.split("/")[1] === "compra-rapida") {
                        setRanges(data.data.ranges);
                    } else {
                        auxRanges.push({...data.data.ranges, prodModCode: product.prodModCode});
                    }

                    aux.push(...data.data.dates);

                    data.data.dates.forEach((dayProduct: any) => {
                        let year = dayProduct.selectedDate.split("-")[0];
                        let month = dayProduct.selectedDate.split("-")[1];
                        let day = dayProduct.selectedDate.split("-")[2];

                        let controlObject: { [x: number]: { price: any; data: any, idTarif: any, priceAdultFinal: any, priceChildFinal: any, priceEldersFinal: any, priceStudentFinal: any, priceGlobalFinal: any, priceInfantFinal: any, idSellingType: any, daysUniqueTarCode: any, prodModUniqueCode: any, tarUniqueCode: any, adultText: string, childText: string, infantText: string, eldersText: string, studentText: string, globalText: string } };
                        let sControl: any = year + "-" + month + "-" + day.split("T")[0];
                        controlObject = {
                            [sControl]: {
                                price:
                                    dayProduct.priceAdultFinal === 0.0
                                        ? dayProduct.priceGlobalFinal
                                        : dayProduct.priceAdultFinal,
                                idTarif: dayProduct.idTarifario,
                                priceAdultFinal: dayProduct.priceAdultFinal,
                                priceChildFinal: dayProduct.priceChildFinal,
                                priceInfantFinal: dayProduct.priceInfantFinal,
                                priceStudentFinal: dayProduct.priceStudentFinal,
                                priceEldersFinal: dayProduct.priceEldersFinal,
                                priceGlobalFinal: dayProduct.priceGlobalFinal,
                                idSellingType: dayProduct.idSellingType,
                                daysUniqueTarCode: dayProduct.daysUniqueTarCode,
                                prodModUniqueCode: dayProduct.prodModUniqueCode,
                                tarUniqueCode: dayProduct.tarUniqueCode,
                                data: year + "-" + month + "-" + day.split("T")[0],
                                adultText: data.data.dates[0][`tariffAgeAdultText${lng !== "BR" ? lng : ''}`],
                                childText: data.data.dates[0][`tariffAgeChildText${lng !== "BR" ? lng : ''}`],
                                infantText: data.data.dates[0][`tariffAgeInfantText${lng !== "BR" ? lng : ''}`],
                                eldersText: data.data.dates[0][`tariffAgeElderText${lng !== "BR" ? lng : ''}`],
                                studentText: data.data.dates[0][`tariffAgeStudentText${lng !== "BR" ? lng : ''}`],
                                globalText: data.data.dates[0][`tariffAgeGlobalText${lng !== "BR" ? lng : ''}`],
                            },
                        };
                        setDayProduct((curObjDeal: any) => ({
                            ...curObjDeal,
                            ...controlObject,
                        }));

                        setAuxTarif(aux);

                        if(window.location.pathname.split("/")[1] !== "compra-rapida") {
                            setRanges(auxRanges);
                        }
                    });
                }
            } catch (error) { }
        }

        const config = {
            headers: { "ngrok-skip-browser-warning": "69420" },
        };

        async function getIdChannel(prod: any) {
            try {
                const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetChannelBySource/site`, config);
                if (data.statusCode === 200) {
                    idChannel = data.data.data;
                    getDays(prod);
                }
            } catch (error) { }
        }

        for (let i = 0; i < product.allTarif.length; i++) {
            getIdChannel(product.allTarif[i]);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.id, productCode, newGetDate, index]);

    if (dayProduct !== null) {
        return (
            <DatePicker
                weekDays={weekDays}
                numberOfMonths={numberMonths}
                disableMonthPicker
                disableYearPicker
                fixMainPosition
                months={months}
                format={configData.DATE.PT}
                // plugins={[<MyPlugin  hours={hoursProduct} />]}
                render={<CustomMultipleInput modalityID={modalityID} valueCalendar={valueCalendar} setNewGetDate={setNewGetDate} />}
                className="multi-locale-days notranslate"
                mapDays={({ date }): any => {

                    let controlNumber =
                        JSON.stringify(date.day).length === 1 ? "0" + date.day : date.day;
                    let controlMonth =
                        JSON.stringify(date.month.number).length === 1
                            ? "0" + date.month.number
                            : date.month.number;
                    let controlYear = date.year;

                    const objCalendar =
                        dayProduct[controlYear + "-" + controlMonth + "-" + controlNumber];
                    if (objCalendar !== undefined && objCalendar.price !== "null") {
                        if (
                            controlYear + "-" + controlMonth + "-" + controlNumber ===
                            objCalendar.data
                        ) {
                            return {
                                children: (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            fontSize: "14px",
                                        }}
                                        onClick={() => { setValueCalendar(objCalendar); handleDateTour(objCalendar) }}
                                    >
                                        <div style={{
                                            textAlign: "center",
                                            position: "absolute",
                                            bottom: "0px",
                                            left: "0px",
                                            right: "0px",
                                            top: "0px",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",

                                        }}>
                                            {date.format("D")}
                                        </div>
                                        {/* <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "9px",
                                                minHeight: "14px",
                                            }}
                                        >
                                            {" "}
                                            {objCalendar !== undefined
                                                ? objCalendar.price !== "null"
                                                    ? `R$ ${objCalendar.price
                                                        .toFixed(2)
                                                        .replace(".", ",")}`
                                                    : ""
                                                : ""}
                                        </div> */}                                        
                                    </div>
                                ),
                            };
                        } else {
                            return {
                                disabled: true,
                            };
                        }
                    } else {
                        return {
                            disabled: true,
                        };
                    }
                }}
            >
                {
                    productCode === "TKT-BF1234"
                        ?
                        <div className="alert-calendar">
                            {
                                i18next.language === "pt"
                                    ?
                                    <div className="p-3" style={{ backgroundColor: "#ebebeb", borderRadius: "20px" }}>
                                        <h5>Atenção!</h5>
                                        <ul>
                                            <li>
                                                Bilhetes Black Friday podem ser agendados até o dia 16/12. Caso a visitação seja após esta data, <a href="/home">clique aqui</a> e compre com a tarifa normal.
                                            </li>
                                            <li>
                                                Datas em cinza, no período de agendamento de Black Friday, indicam que o estoque já esgotou. Caso deseje visitar o Parque em data não disponível, <a href="/home">clique aqui</a> e compre o bilhete com tarifa normal.
                                            </li>
                                        </ul>
                                    </div>
                                    :
                                    i18next.language === "en"
                                        ?
                                        <div className="p-3" style={{ backgroundColor: "#ebebeb", borderRadius: "20px" }}>
                                            <h5>Attention!</h5>
                                            <ul>
                                                <li>
                                                    Black Friday tickets can be booked until 12/16. If you wish to visit the park after this date, <a href="/home">click here</a> and buy you ticket with normal rate.
                                                </li>
                                                <li>
                                                    Dates in gray, during Black Friday period, indicate that tickets are sold out. If you want to visit the Park on a date shown as not available, <a href="/home">click here</a> and buy the ticket at normal rate.
                                                </li>
                                            </ul>
                                        </div>
                                        :
                                        i18next.language === "es"
                                            ?
                                            <div className="p-3" style={{ backgroundColor: "#ebebeb", borderRadius: "20px" }}>
                                                <h5>¡Aviso!</h5>
                                                <ul>
                                                    <li>
                                                        Las entradas del Black Friday se pueden reservar hasta el 16/12. Si la visita es posterior a esta fecha, haga <a href="/home">clic aquí</a> y compre con la tarifa normal.
                                                    </li>
                                                    <li>
                                                        Las fechas en gris, en el periodo de programación del Black Friday, indican que el stock ya se ha agotado. Si quieres visitar el Parque en una fecha no disponible, haz <a href="/home">clic aquí</a> y compra la entrada a la tarifa normal.
                                                    </li>
                                                </ul>
                                            </div>
                                            :
                                            <div className="p-3" style={{ backgroundColor: "#ebebeb", borderRadius: "20px" }}>
                                                <h5>Atenção!</h5>
                                                <ul>
                                                    <li>
                                                        Bilhetes Black Friday podem ser agendados até o dia 16/12. Caso a visitação seja após esta data, <a href="/home">clique aqui</a> e compre com a tarifa normal.
                                                    </li>
                                                    <li>
                                                        Datas em cinza, no período de agendamento de Black Friday, indicam que o estoque já esgotou. Caso deseje visitar o Parque em data não disponível, <a href="/home">clique aqui</a> e compre o bilhete com tarifa normal.
                                                    </li>
                                                </ul>
                                            </div>
                            }
                        </div>
                        :
                        ""
                }
                <div style={{paddingBottom: "4px"}}>
                    <CalendarMessage />
                </div>
            </DatePicker>
        );
    } else {
        return <></>;
    }
};

export default DoubleCalendarTicket;