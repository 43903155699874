import { useEffect, useState } from "react";
import i18next from "i18next";
import Helmet from 'react-helmet';

import Accordion from "react-bootstrap/Accordion";
import Button from "@restart/ui/esm/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Row, Col } from "react-bootstrap";

import { Link as LinkRoll } from "react-scroll";

import "./TourCaracol.scss";

import api from "../../services/api";

import ProductSlider from "../../components/Sliders/ProductSlider/ProductSlider";
import TourOptions from "../../components/ProductOptions/TourOptions";

import CardTourSide from "../../components/CardTourSide/CardTourSide";

//import SetCartItem from "../../components/Cart/SetCartItem";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { addItemCart } from "../../store/cart";
//import DiscountRules from "../../components/DiscountRules/DiscountRules";
import PageDafault from "../../templates/PageDafault";

import TagManager from 'react-gtm-module';
//import TourPromotion from "./TourPromotion";
import Location from "../../components/Location/Location";
import AcessRules from "../../components/AccessRules/AccessRules";
import DiscountRulesCaracol from "../../components/DiscountRules/DiscountRulesCaracol";

const TourCaracol = () => {
  const { t } = useTranslation();
  const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';
  const [tourResponse, setTourResponse] = useState<any>("null");
  const [optionTour, setOptionTour] = useState<any>([]);
  const [cardTourSide, setCardTourSide] = useState<any>([]);
  const [alert, setAlert] = useState<any>(false);
  const cartBPA: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  const dispatch = useAppDispatch();

  const url = window.location.href;
  const codeUrl = url.split("/");
  const slugUrl = codeUrl[codeUrl.length - 1].split('?')[0];

  const addToCart = (itemOption: any, productCode: any) => {

    var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

    cartBPA.dados.forEach((elem: any) => {
      if (elem.productCode === productCode) {
        repeatedItem = true
      }
    });

    if (repeatedItem === true) {
      setAlert(true);
    } else {
      const item = {
        productName: tourResponse.productName,
        productNameBR: tourResponse.productNameBR,
        productNameEN: tourResponse.productNameEN,
        productNameES: tourResponse.productNameES,
        imagesBaseUrl: tourResponse.imagesBaseUrl,
        imgCart: tourResponse.images[0],
        price: itemOption?.tarif?.price,

        idTarif: itemOption.idTarif,
        idPickup: itemOption.idPickup,

        priceAdults: itemOption.priceAdults,
        priceChilds: itemOption.priceChilds,
        priceInfants: itemOption.priceInfants,
        priceElders: 0,
        priceStudent: 0,
        priceGlobalPeople: itemOption.priceGlobalPeople,
        reservationSystem: tourResponse.reservationSystem,

        ranges: itemOption.ranges,
        hours: itemOption.hours,

        sellingType: tourResponse.idSellingType,
        adults: itemOption.adults,
        childs: itemOption.childs,
        infants: itemOption.infants,
        elders: itemOption.elders,
        student: itemOption.student,
        globalPeople: itemOption.globalPeople,
        productType: 1,
        productCode: tourResponse.productCode,
        productSlug: slugUrl,
        slugBR: tourResponse.slugBR,
        slugEN: tourResponse.slugEN,
        slugES: tourResponse.slugES,
        time: itemOption.time,
        date: itemOption.date,
        supplier: tourResponse.supplier,
        typePickup: itemOption.embarkingType,
        meetingPoint: itemOption.embarkingType === "0" ? itemOption.meetingPoint : '',
        pickupListId: itemOption.embarkingType === "0" ? 0 : itemOption.pickupListId,
        pickup: 0,

        discount: 0,
        customValueNet: 0,
        customValueSell: 0,
        goingSource: "null",
        commingSource: "null",
        latOrigem: "null",
        lngOrigem: "null",
        latDestino: "null",
        lngDestino: "null",
        cia: "null",
        voo: "null",
        smallSuitcase: 0,
        bigSuitcase: 0,
        internalNotes: " ",
        idVeiculo: 0,
        passengers: []

      }

      //SetCartItem(item);
      dispatch(addItemCart(item));
      window.location.href = window.location.origin + '/checkout';
    }
  }

  useEffect(() => {

    async function getItems() {
      try {
        const { data } = await api.post(
          `${process.env.REACT_APP_SERVER_URL_API}/api/Products/FetchSingleTourAsync`,
          {
            productCode: "",
            lang: lng,
            Slug: `${slugUrl}`,
          }
        );
        if (data.status !== 400) {
          if (data.statusCode === 204) {
            window.location.href = "/404";
          } else {
            setTourResponse(data.data);
            setOptionTour(data.data.modalities);
            setCardTourSide(data.data.modalities);
          }
        }
      } catch (error) { }
    }
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newSlug = (i18next.language === "pt" ? tourResponse.slugBR : i18next.language === "en" ? tourResponse.slugEN : i18next.language === "es" ? tourResponse.slugES : tourResponse.slugBR);
    if (slugUrl !== newSlug && newSlug !== undefined) {
      window.location.href = window.location.origin + "/tour/" + newSlug;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  useEffect(() => {
    if (tourResponse !== "null") {
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: {
            detail: {
              products: [{
                name: tourResponse.productName,         // Name or ID is required.
                id: tourResponse.productCode,
                price: tourResponse.modalities[0].tarif.price,
                brand: 'Bondinho',
              }]
            }
          }
        }
      });
    }
  }, [tourResponse]);

  if (tourResponse !== "null") {
    const ldJson = {
      "@context": "http://schema.org/",
      "@type": "TouristAttraction",
      "description": tourResponse.productInfo.replace(/<[^>]*>?/gm, ''),
      "image": tourResponse.imagesBaseUrl + '/medium_' + tourResponse.images[0],
      "name": process.env.REACT_APP_SERVER_NAME,
      "url": process.env.REACT_APP_SERVER_URL
    };

    return (
      <>
        <Helmet>
          <html lang={i18next.language} />
          <title>{tourResponse.productName + ' | ' + process.env.REACT_APP_SERVER_NAME}</title>
          <meta name="description" content={tourResponse.productInfo.replace(/<[^>]*>?/gm, '')} />
          <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

          {/* Essential META Tags */}
          <meta property="og:title" content={tourResponse.productName + ' | ' + process.env.REACT_APP_SERVER_NAME} />
          <meta property="og:type" content="TouristAttraction" />
          <meta property="og:image" content={`${tourResponse.imagesBaseUrl}/medium_${tourResponse.images[0]}`} />
          <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

          {/* Non-Essential, But Recommended */}
          <meta property="og:description" content={tourResponse.productInfo.replace(/<[^>]*>?/gm, '')} />
          <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

          {/* Non-Essential, But Required for Analytics */}
          {/* <meta property="fb:app_id" content="your_app_id" /> */}

          {
            tourResponse.productCode === "PXY4929" || tourResponse.productCode === "PXC8157" || tourResponse.productCode === "PXE9656"
              ?
              <meta name="robots" content="noindex, nofollow" />
              :
              <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
          }


          <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />

          <script type="application/ld+json">
            {JSON.stringify(ldJson)}
          </script>
        </Helmet>

        {/* {
          tourResponse.productCode !== process.env.REACT_APP_PROMO_PROD_01
            ? */}
        <PageDafault>
          <div className="bg-light">
            <nav className="navbar bg-light d-none" id="navbar-destino-top">
              <ul className="nav container">
                <li className="nav-item dropdown ">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  ></Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  ></div>
                </li>
              </ul>
            </nav>
            <div className="container destino text-dark bg-light">
              <div className="row ">
                <div
                  className="col-12 col-lg-9 order-2 order-sm-2 order-md-1 px-0 px-md-3"
                  data-spy="scroll"
                  data-target="#navbar-destino"
                  data-offset="0"
                >
                  <div className="col-12 p-4">
                    {/* Carrosel de imagens */}
                    <section>
                      <div id="carousel-thumb" className="caracol">
                        <ProductSlider 
                        arrayImages={tourResponse.images} 
                        imagesBaseUrl={tourResponse.imagesBaseUrl} />
                      </div>
                    </section>
                    {/* Carrosel de imagens */}

                    {/* Navegador Tour */}
                    <div className="conteudo">
                      <nav
                        id="navbar-destino"
                        className="navbar navbar-light bg-white mb-5"
                      >
                        <div
                          className="container text-center p-0 m-0"
                          style={{ display: "block" }}
                        >
                          <ul className="nav-pills scroll-nav">
                            <Row>
                              <Col md={9} className="flex-column">
                                <Row>
                                  <Col md={3}>
                                    <li className="nav-item">
                                      <LinkRoll
                                        activeClass="active"
                                        to="session-experience"
                                        spy={true}
                                        smooth={true}
                                        offset={-80}
                                        duration={500}
                                      >
                                        <span className="nav-link link-tour nav-tour">
                                          {" "}
                                          <p className="d-md-inline d-lg-inline text-md-uppercase">
                                            <FontAwesomeIcon
                                              icon={["fal", "grin-wink"]}
                                              size="1x"
                                              style={{ marginRight: "5px" }}
                                            />
                                            <span>{t("tour.theExperience")}</span>
                                          </p>
                                        </span>
                                      </LinkRoll>
                                    </li>
                                  </Col>
                                  <Col md={3}>
                                    <li className="nav-item">
                                      <LinkRoll
                                        activeClass="active"
                                        to="modalidades"
                                        spy={true}
                                        smooth={true}
                                        offset={-80}
                                        duration={500}
                                      >
                                        <span className="nav-link link-tour nav-tour">
                                          {" "}
                                          <p className="d-lg-inline text-md-uppercase">
                                            <FontAwesomeIcon
                                              icon={["fal", "list"]}
                                              size="1x"
                                              style={{ marginRight: "5px" }}
                                            />
                                            <span>{t("tour.options")}</span>
                                          </p>
                                        </span>
                                      </LinkRoll>
                                    </li>
                                  </Col>
                                  <Col md={3}>
                                    <li className="nav-item">
                                      <LinkRoll
                                        activeClass="active"
                                        to="session-info"
                                        spy={true}
                                        smooth={true}
                                        offset={-140}
                                        duration={500}
                                      >
                                        <span className="nav-link link-tour nav-tour">
                                          {" "}
                                          <p className="d-lg-inline text-md-uppercase">
                                            <FontAwesomeIcon
                                              icon={["fal", "info-circle"]}
                                              size="1x"
                                              style={{ marginRight: "5px" }}
                                            />
                                            <span>{t("tour.Information")}</span>
                                          </p>
                                        </span>
                                      </LinkRoll>
                                    </li>
                                  </Col>
                                  <Col md={3}>
                                    <li className="nav-item">
                                      <LinkRoll
                                        activeClass="active"
                                        to="session-contact"
                                        spy={true}
                                        smooth={true}
                                        offset={-80}
                                        duration={500}
                                      >
                                        <span className="nav-link link-tour nav-tour">
                                          {" "}
                                          <p className="d-lg-inline text-md-uppercase">
                                            <FontAwesomeIcon
                                              icon={["fal", "phone-rotary"]}
                                              size="1x"
                                              style={{ marginRight: "5px" }}
                                            />
                                            <span>{t("tour.contact")}</span>
                                          </p>
                                        </span>
                                      </LinkRoll>
                                    </li>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={3}>
                                <li className="nav-item">
                                  <LinkRoll
                                    className="nav-link link-tour nav-tour btn-see-options bg-primary"
                                    activeClass="active"
                                    to="modalidades"
                                    spy={true}
                                    smooth={true}
                                    offset={-80}
                                    duration={500}
                                    style={{
                                      width: "135px",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    {t("tour.buyBtn")}
                                  </LinkRoll>
                                </li>
                              </Col>
                            </Row>
                          </ul>
                        </div>
                      </nav>
                      {/* Navegador Tour */}

                      <div className="row d-flex align-content-end">
                        <div className="col-md-12 py-2">
                          <h1 className="h1 font-weight-bold pb-2 text-primary" id="name-product">
                            {tourResponse.productName}
                          </h1>
                        </div>
                      </div>

                      {/* Conteúdo do passeio */}

                      <h5
                        className="text-uppercase text-primary"
                        id="session-experience"
                      >
                        <FontAwesomeIcon
                          icon={["fal", "smile"]}
                          size="1x"
                          style={{ marginRight: "5px" }}
                        />
                        {t("tour.theExperience")}
                      </h5>
                      <span
                        id="description"
                        dangerouslySetInnerHTML={{
                          __html: tourResponse.productDescription,
                        }}
                      ></span>

                      <h5 className="text-uppercase text-primary mt-2">
                        <FontAwesomeIcon
                          icon={["fal", "info-circle"]}
                          size="1x"
                          style={{ marginRight: "5px" }}
                        />
                        {t("tour.informations")}
                      </h5>
                      <span
                        id="infos-product"
                        dangerouslySetInnerHTML={{
                          __html: tourResponse.productInfo,
                        }}
                      ></span>

                      <h5 className="text-uppercase text-primary mt-2">
                        <FontAwesomeIcon
                          icon={["fal", "star"]}
                          size="1x"
                          style={{ marginRight: "5px" }}
                        />
                        {t("tour.highlights")}{" "}
                      </h5>
                      <ul id="highlights">
                        {tourResponse.highlights.map((h: any, index: any) => {
                          return <li key={index}> {h} </li>;
                        })}
                      </ul>

                      <h5 className="text-uppercase text-primary mt-2">
                        <FontAwesomeIcon
                          icon={["fal", "star"]}
                          size="1x"
                          style={{ marginRight: "5px" }}
                        />
                        {t("tour.details")}
                      </h5>
                      <ul id="details-product">
                        {tourResponse.details.map((d: any, index: any) => {
                          return <li key={index}> {d} </li>;
                        })}
                      </ul>
                      {/* Conteúdo do passeio */}

                      {/* Opções do passeio */}
                      <section
                        id="modalidades"
                        className=" my-3"
                        data-destine="modalidades"
                      >
                        <h5 className="text-uppercase text-primary pb-4">
                          <FontAwesomeIcon
                            icon={["fal", "list"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          {t("tour.options")}
                        </h5>
                        {/*<TourOptions
                          options={optionTour}
                          response={tourResponse}
                          action={addToCart}
                        ></TourOptions>*/}
                      </section>
                      {/* Opções do passeio */}

                      <div className="accordion accordion-rules" id="info">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <span
                                className="h4 text-primary font-weight-normal text-uppercase"
                                id="infos"
                              >
                                {t("tour.discountRules")}
                              </span>
                              <i className="fal float-right p-0"></i>
                            </Accordion.Header>
                            <Accordion.Body>
                              <DiscountRulesCaracol tourResponse={tourResponse.productCode} />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey="0" className="mt-4">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <span
                                className="h4 text-primary font-weight-normal text-uppercase"
                                id="politica"
                              >
                                {t("navHeader.sideBar.howtoget")}
                              </span>
                              <i className="fal float-right p-0"></i>
                            </Accordion.Header>
                            <Accordion.Body>
                              <Location />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey="0" className="mt-4">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <span
                                className="h4 text-primary font-weight-normal text-uppercase"
                                id="politica"
                              >
                                {t("navHeader.sideBar.acessRules")}
                              </span>
                              <i className="fal float-right p-0"></i>
                            </Accordion.Header>
                            <Accordion.Body>
                              <AcessRules />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey="0" className="mt-4">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <span
                                className="h4 text-primary font-weight-normal text-uppercase"
                                id="politica"
                              >
                                {t("tour.cancel")}
                              </span>
                              <i className="fal float-right p-0"></i>
                            </Accordion.Header>
                            <Accordion.Body>
                              {t("tour.cancelText")}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>

                      <div className="my-4">
                        <div className="row m-0 overflow-hidden">
                          <div className="col-12 col-sm-6 col-md- col-lg- col-xl-8 align-items-center d-flex bg-white px-3 py-2">
                            <h4 id="questoes" className="py-0">
                              {" "}
                              {t("tour.code")}?
                            </h4>
                          </div>

                          <div className="col-12 col-sm-6 col-md- col-lg- col-xl-4 p-0 bg-primary text-white align-items-center justify-content-center d-flex">
                            <span id="product-code">
                              {t("tour.code")}: {tourResponse.productCode}
                            </span>
                          </div>
                        </div>
                        <div id="session-contact" className="row mt-4">
                          <div className="col-12 text-center">
                            <p>{t("tour.emergencyContacts")}</p>
                          </div>
                        </div>
                        <div className="row text-center justify-content-evenly">
                          <div className="col-12 col-sm-4">
                            <FontAwesomeIcon
                              icon={["fal", "envelope"]}
                              size="2x"
                              style={{ color: "#000", width: "32px", opacity: "0.6" }}
                            />
                            <span className="d-block lead pt-1 pb-2" id="mail-info">
                              {" "}
                              {process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}
                            </span>
                            <a
                              href={`mailto:${process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}`}
                              title=""
                              className="btn btn-sm btn-outline-primary btn-contact"
                            >
                              {t("tour.sendEmail")}
                            </a>
                          </div>
                          <div className="col-12 col-sm-4 pb-3 pb-sm-0">
                            <FontAwesomeIcon
                              icon={["fab", "whatsapp"]}
                              size="2x"
                              style={{ color: "#000", width: "32px", opacity: "0.6" }}
                            />
                            <span
                              className="d-block lead pt-1 pb-2"
                              id="whats-info"
                            >
                              {process.env.REACT_APP_INFO_WHATSAPP}
                            </span>
                            <a
                              href={`https://api.whatsapp.com/send?phone=55${process.env.REACT_APP_INFO_WHATSAPP?.replaceAll("(54) ", "54").replaceAll("-", "")}`}
                              title=""
                              className="btn btn-sm btn-outline-primary btn-contact"
                            >
                              {t("tour.clackChat")}
                            </a>
                          </div>
                          <div className="col-12 col-sm-4 pb-3 pb-sm-0">
                            <FontAwesomeIcon
                              icon={["fal", "phone"]}
                              size="2x"
                              style={{ color: "#000", width: "32px", opacity: "0.6" }}
                            />
                            <span className="d-block lead pt-1 pb-2" id="tel-info">
                              {process.env.REACT_APP_INFO_PHONE}
                            </span>
                            <a
                              href={`tel:${process.env.REACT_APP_INFO_PHONE}`}
                              title=""
                              className="btn btn-sm btn-outline-primary btn-contact"
                            >
                              {t("tour.clickCall")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-3 order-2 order-sm-2 order-md-1 d-none d-lg-block">
                  <CardTourSide options={cardTourSide} />
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal-confirm"
            show={alert}
            onHide={() => {
              setAlert(false)
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <div className='modal-body text-center'>
              <div>
                <FontAwesomeIcon
                  icon={["fal", "times-circle"]}
                  size="5x"
                  style={{ fontSize: "7.5em" }}
                />

              </div>
              <div className="px-3 px-md-5">
                {t("tour.alertRepeatedItem")}
              </div>
              <div>
                <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlert(false)}>Fechar</Button>
              </div>
            </div>
          </Modal>
        </PageDafault>
        {/*      :
            <>
              <TourPromotion />
            </>
        } */}
      </>
    );
  } else {
    return <></>;
  }
};

export default TourCaracol;
