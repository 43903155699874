import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Link as LinkRoll } from "react-scroll";

import { Button } from "react-bootstrap";

function HowToGetCaracolPT() {
    return (
        <>
            <div className="how-to-get-caracol">
                <div className="how-to-get-top">
                    <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />
                    <span>Onde Estamos</span>
                </div>

                <div className="how-to-get-description">
                    <p>
                        O <b>Parque do Caracol</b> fica a aproximadamente 8,7 km do centro da cidade de Canela/RS. Basta seguir em direção a Gramado/RS, pegando a RS 466.
                    </p>
                    <p>
                        Estacionamento cobrado no local.
                    </p>
                </div>
               
            </div>
        </>
    )
}

export default HowToGetCaracolPT;