function TheParkPT() {
    return (
        <div className='park-content'>
            <div className='py-5 px-3'>
                <p><strong>O Parque do Caracol está prestes a passar por uma transformação significativa!</strong></p>
                <p> Com o objetivo de tornar sua experiência ainda mais inesquecível, estamos preparando uma Nova História para o parque. Vamos inaugurar um <strong>novo deck de observação</strong> com uma vista única para a Cascata do Caracol, expandindo de 40m² para 800m², proporcionando uma área maior para os visitantes aproveitarem a vista. O deck contará com uma <strong>plataforma suspensa sobre o vale</strong>, a 40 metros de altura, oferecendo uma experiência única para quem busca adrenalina e paisagens de tirar o fôlego. Você poderá caminhar sobre um dos cenários mais impressionantes do parque enquanto se conecta com o ambiente. Além disso, a <strong>Trilha do Moinho</strong> receberá melhorias, incluindo um novo <strong>Deck do Moinho</strong> para uma visão mais próxima de outra das espetaculares cascatas do parque, proporcionando novas alternativas para explorar e aproveitar a natureza. </p>
                <p> Um ícone do parque, a <strong>Trilha Elevada Perna Bamba</strong>, será reaberta, levando os visitantes até a base da Cascata do Caracol para apreciar sua queda de 131 metros de perto. Com mais de 700 degraus, é ideal para aventureiros que desejam experimentar a atração a partir de novos pontos de vista. A <strong>área de churrasqueiras</strong> também será revitalizada, expandindo de três quiosques para dez. A estrutura modernizada proporcionará um ambiente mais confortável onde você poderá reunir amigos e familiares para um tradicional churrasco gaúcho na tranquila atmosfera do parque. </p>
                <p> Para os amantes de emoção, apresentamos o <strong>Salto de Pêndulo</strong>, uma queda livre de 100 metros, oferecendo uma experiência emocionante de aventura extrema em meio à natureza! Durante a construção do novo Parque do Caracol, que trará uma série de atrações inesquecíveis, o parque ficará temporariamente fechado para garantir a segurança dos visitantes e da equipe. </p>
                <p> <strong>Fique ligado</strong> no nosso Instagram oficial (<a href="https://www.instagram.com/parquedocaracol">@parquedocaracol</a>) para atualizações e mais detalhes sobre a transformação do parque. </p>
                {/* <p>
                    O Parque do Caracol é um destino imperdível para quem busca aventura, diversão e momentos inesquecíveis em meio à natureza da Serra Gaúcha. Localizado em Canela - RS, o parque oferece uma experiência completa para toda a família. Sua principal atração é um verdadeiro espetáculo da natureza: a Cascata do Caracol, com uma queda livre de 131 metros, que pode ser apreciada no <strong>mirante</strong> onde há uma vista privilegiada.
                </p>
                <p>
                    Além do atrativo principal - a <strong>Cascata do Caracol</strong>, o Parque também oferece uma jornada completa com <strong>outras atrações imperdíveis</strong> para você explorar, a começar pelas <strong>trilhas ecológicas</strong>, que oferecem uma experiência incrível em meio à rica fauna e flora local.
                </p>
                <p>
                    Outra atração perfeita para todos os tipos de público é a <strong>área do piquenique</strong>, ideal para curtir com família ou amigos. É possível também aproveitar o <strong>playground</strong> com a criançada com diversas opções de brinquedos, incluindo escorrega, balanço e uma pequena tirolesa que diverte crianças e adultos. Nosso espaço é inclusivo e tem brinquedos adaptados para portadores de necessidades especiais.
                </p>
                <p>
                    Pra quem gosta de relaxar e contemplar a natureza, vai encontrar ao seu dispor uma <strong>área de redário</strong> para dar uma pausa na correria do dia a dia. Para confraternizar, tem a <strong>área de churrasqueira</strong> para reunir pessoas especiais. Temos ainda um <strong>restaurante</strong> para saborear uma comida típica, caseira e feita em fogão à lenha com música ao vivo nos finais de semana e diversas <strong>lojas</strong> para garantir presentes.
                </p>
                <p>
                    Você também poderá comprar ingressos para subir no <strong>Observatório Panorâmico</strong>, que fica a uma altura de 27m, com uma visão 360º do parque, para admirar a cascata na copa das árvores. E para o passeio de trenzinho pela <strong>Estação do Sonho Vivo</strong>, com 800m de extensão, para viajar no tempo e descobrir um pouco da história da colonização da região.
                </p>

                <p>
                    Vivencie tudo isso ao lado de companhias especiais, no cartão postal da Serra Gaúcha. Aproveite o dia no Parque do Caracol em uma jornada rumo à cascata.
                </p>
                <p>
                    Fique de olho no instagram oficial (<a href="https://www.instagram.com/parquedocaracol">@parquedocaracol</a>) para acompanhar a programação do parque.
                </p>
                <p>
                    <strong>Atenção:</strong> <br />
                    Para desfrutar do ambiente de forma tranquila, evite sair das trilhas e observe a sinalização; descarte seus resíduos nos locais indicados; e não alimente os animais silvestres.
                </p>

                <p>
                Outras regras importantes:
                    <ul>
                        <li>Não colete plantas, animais e minerais;</li>
                        <li>Evite fumar no parque;</li>
                        <li>Proibido fazer fogueiras;</li>
                        <li>Não tome banho e nem beba a água do Arroio Caracol.</li>
                    </ul>
                </p> */}
            </div>
        </div>
    )
}

export default TheParkPT;