import React from "react";
import { useTranslation } from "react-i18next";

import './CalendarMessage.scss';

const CalendarMessage: React.FC = () => { 
    const [t] = useTranslation();  
  
    return (
      <div  className="calendar-message">
        <p className="calendar-message-title">{t('calendarMessage.attention')}</p>
        <p className="calendar-message-content">{t('calendarMessage.content')}</p>
      </div>
    );
  };
  
  export default CalendarMessage;

