function TheParkEN() {
    return (
        <div className='park-content'>
            <div className='py-5 px-3'>
            <p><strong>Parque do Caracol is prepared to undergo a significant transformation!</strong></p>
                <p>
                    With the goal in mind of making your experience even more unforgettable, we are preparing a New Story for the park. We will introduce a <strong>new observation deck</strong> with an unique view of the Caracol Cascade, expanding from 40m² to 800m², providing a larger area for visitors to enjoy the view. The deck will feature a <strong>suspended platform over the valley</strong>, 40 meters high, offering a unique experience for those seeking adrenaline and breathtaking landscapes. You can walk above one of the park's most impressive settings while connecting with the environment. Additionally, the <strong>Mill Trail</strong> will receive upgrades, including a new <strong>Mill Deck</strong> for a closer view of another one of the park's spectacular cascades, giving visitors new alternatives to explore and enjoy nature.
                </p>
                <p>
                    A park icon, the <strong>Perna Bamba Elevated Trail</strong>, will be reopened, taking visitors down to the base of the Caracol Cascade to appreciate its 131-meter drop up close. With over 700 steps, it is ideal for adventure seekers wanting to experience the attraction from new viewpoints. The <strong>barbecue area</strong> will also be revitalized, expanding from three kiosks to ten. The modernized structure will provide a more comfortable environment where you can gather friends and family for a traditional Gaúcho barbecue in the park's tranquil surroundings.
                </p>
                <p>
                    For thrill-seekers, we introduce the <strong>Pendulum Jump</strong> a 100-meter free fall, offering an exhilarating experience of extreme adventure amidst nature! During the construction of the new Parque do Caracol, which will bring a host of unforgettable attractions, the park will be temporarily closed to ensure the safety of visitors and staff.
                </p>
                <p>
                <strong>Stay tuned</strong> on our official Instagram (<a href="https://www.instagram.com/parquedocaracol">@parquedocaracol</a>) for updates and more details about the park's transformation.
                </p>
                {/* <p>
                    The Parque do Caracol is a must-visit destination for those seeking adventure, fun, and unforgettable moments amidst the nature of the Serra Gaúcha region. Located in Canela - RS, the park offers a complete experience for the whole family. Its main attraction is a true spectacle of nature: the Caracol Cascade, with a free fall of 131 meters, which can be appreciated from the <strong>observation deck</strong> where there is a privileged view.
                </p>
                <p>
                    In addition to the main attraction — <strong>the Caracol Cascade</strong> —, the park also offers a complete journey with <strong>other must-see attractions</strong> for you to explore, starting with the <strong>ecological trails</strong> which offer an incredible experience amidst the rich local fauna and flora.
                </p>
                <p>
                    Another perfect attraction for all types of audiences is the <strong>picnic area</strong>, ideal for enjoying with family or friends. You can also enjoy the <strong>playground</strong> with the kids, with slides, swings, and a small zipline that is fun for children and adults. Our space is inclusive and has toys adapted for people with special needs.
                </p>
                <p>
                    For those who like to relax and contemplate nature, there is a <strong>hammock area</strong> at your disposal to take a break from the hustle and bustle of everyday life. If you want a place to celebrate special occasions, there is a <strong>barbecue area</strong> to gather special people. We also have a <strong>restaurant</strong> for you to savor typical, homemade food cooked on a wood stove, with live music on weekends and several <strong>shops</strong> with gifts and souvenirs.
                </p>
                <p>
                    You can also buy tickets to climb the <strong>Panoramic Observatory</strong>, which is 27m high, with a 360º view of the park, to admire the cascade in the treetops. And for the train ride through the <strong>Sonho Vivo Station</strong>, 800m in length, to travel back in time and learn more about the history of the region's colonization.
                </p>
                <p>
                    Experience it all with those you love, at the postcard of the Serra Gaúcha. Enjoy the day at Parque do Caracol on a journey towards the waterfall.
                </p>
                <p>
                    Keep an eye on the official Instagram (<a href="https://www.instagram.com/parquedocaracol">@parquedocaracol</a>) to follow the park's schedule.
                </p>
                <p>
                    <strong>Attention:</strong> <br />
                    To enjoy the environment safely, follow the signage and avoid leaving the trails; dispose of your waste in the designated areas; and do not feed wild animals.
                </p>
                <p>
                    Other important rules:
                    <ul>
                        <li>Do not collect plants, animals, and minerals.</li>
                        <li>Avoid smoking in the park.</li>
                        <li>Fires are prohibited.</li>
                        <li>Do not bathe or drink water from the Caracol Stream.</li>
                    </ul>
                </p> */}
            </div>
        </div>
    )
}

export default TheParkEN;