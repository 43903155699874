import React, { /* useEffect,  */useState } from 'react';
import Slider from 'react-slick';
import i18next from 'i18next';

//import { Link as LinkRoll } from 'react-scroll';

import { Container, Row } from 'react-bootstrap';

/* Imagens do Slider Padrão */

import BannerCaracolDesk from '../../../assets/img/bannerHomeCaracol/fechamento/banner_br.png';
import BannerCaracolDeskUS from '../../../assets/img/bannerHomeCaracol/fechamento/banner_us.png';
import BannerCaracolDeskES from '../../../assets/img/bannerHomeCaracol/fechamento/banner_es.png';
import BannerCaracolDeskMobilePT from '../../../assets/img/bannerHomeCaracol/fechamento/mobile.png';
import BannerCaracolDeskMobileEN from '../../../assets/img/bannerHomeCaracol/fechamento/mobile_us.png';
import BannerCaracolDeskMobileES from '../../../assets/img/bannerHomeCaracol/fechamento/mobile_es.png';


import BannerNovaHistoriaDesktop from '../../../assets/img/bannerHomeCaracol/nova-historia-desktop.png';
import BannerNovaHistoriaMobile from '../../../assets/img/bannerHomeCaracol/nova-historia-mobile.png';
/* END - Imagens do Slider Padrão */

import './BannerHomeCaracol.scss';
import { Link } from 'react-router-dom';

function BannerHomeCaracol() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changeBanner, setChangeBanner] = useState<boolean>();

  const settings = {
    draggable: false,
    useTransform: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 5500,
    initialSlide: 0 /* Math.floor(Math.random() * 2) */,
    cssEase: 'ease-out',
  };

  const typeDevice = window.innerWidth < 480 ? 'mobile' : 'desktop';

  /* Mudar o Routes para o padrão no dia 30/06/2022 às 19:00 */
  /*   useEffect(() => {
      let date = new Date().toLocaleDateString('pt-BR');
  
      let dateNow = date.split(' ')[0].split('/').reverse().join('');
  
      if (`${dateNow}` >= '20231005' && `${dateNow}` <= '20231016') {
        setChangeBanner(true);
      } else {
        setChangeBanner(false);
      }
    }, []); */
  /* END - Mudar o Routes para o padrão no dia 30/06/2022 às 19:00 */

  return (
    <div className="bg-main-banner">
      <Slider {...settings}>
        <Link 
          to={{
            pathname: "https://comunicados.parquecaracol.com.br/uma-nova-historia",
          }}
         target="_blank"
          rel="noopener noreferrer"
        >
          <Container className="bg-secondary banner-home p-0 m-0" fluid>
            <Container className="p-0 m-0" fluid>
              {/*<LinkRoll
                      activeClass="active"
                      to="main-product"
                      spy={true}
                      smooth={true}
                      offset={-250}
                      duration={500}
          >*/}
              <Row className="p-0 m-0">
                {typeDevice === 'desktop' ? (
                  <img
                    className="p-0 m-0"
                    src={
                      i18next.language === 'pt'
                        ? BannerCaracolDesk
                        : i18next.language === 'en'
                          ? BannerCaracolDeskUS
                          : i18next.language === 'es'
                            ? BannerCaracolDeskES
                            : BannerCaracolDesk
                    }
                    alt="Banner do Parque do Caracol com os dizeres conheça o parque do caracol, onde a natureza e os sorrisos se encontram."
                  />
                ) : (
                  <img
                    className="p-0 m-0"
                    src={
                      i18next.language === 'pt'
                        ? BannerCaracolDeskMobilePT
                        : i18next.language === 'en'
                          ? BannerCaracolDeskMobileEN
                          : i18next.language === 'es'
                            ? BannerCaracolDeskMobileES
                            : BannerCaracolDeskMobilePT
                    }
                    alt="Banner do Parque do Caracol com os dizeres conheça o parque do caracol, onde a natureza e os sorrisos se encontram."
                  />
                )}
              </Row>


              {/*</LinkRoll>*/}
            </Container>
          </Container>
        </Link>

        {/* <a target='_blank'
          href={`https://comunicados.parquecaracol.com.br/uma-nova-historia`}
        >
          <Container className="bg-secondary banner-home p-0 m-0" fluid>
            <Container className="p-0 m-0" fluid>
              <Row className="p-0 m-0">
                {typeDevice === 'desktop' ? (
                  <img
                    className="p-0 m-0"
                    src={BannerNovaHistoriaDesktop}
                    alt="Banner Uma Nova História"
                  />
                ) : (
                  <img
                    className="p-0 m-0"
                    src={BannerNovaHistoriaMobile}
                    alt="Banner Uma Nova História"
                  />
                )}
              </Row>
            </Container>
          </Container>
        </a> */}
      </Slider>
    </div>
  );
}

export default BannerHomeCaracol;
