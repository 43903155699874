function TheParkES() {
    return (
        <div className='park-content'>
            <div className='py-5 px-3'>
            <p><strong>¡El Parque do Caracol está a punto de pasar por una gran transformación!</strong></p>
                <p>Con el objetivo de hacer que tu experiencia sea aún más inolvidable, estamos preparando una Nueva Historia para el parque. Tendremos un <strong>nuevo mirador</strong> con una vista inigualable de la Cascada del Caracol, que pasará de 40m² a 800m², garantizando un espacio más amplio para disfrutar aún mejor de la vista. El espacio también contará con una <strong>plataforma suspendida sobre el valle</strong> a 40 metros de altura. Para quienes buscan adrenalina y paisajes deslumbrantes, la plataforma ofrecerá una experiencia única. Siente la emoción de caminar sobre uno de los paisajes más impresionantes del parque mientras te conectas con el entorno. Además, el <strong>Sendero del Molino</strong>, uno de los tres senderos ecológicos del parque, también será mejorado y contará con una nueva <strong>Terraza del Molino</strong> para ofrecerte una vista más cercana de otra de las espectaculares cascadas del parque, proporcionando nuevas perspectivas para explorar y apreciar la naturaleza.</p>
                <p>Un ícono del parque, el <strong>Sendero Elevado de la Perna Bamba</strong>, será reabierto. Un sendero que lleva al visitante hasta la base de la Cascada del Caracol para apreciar de cerca sus 131 metros de caída de agua. Con más de 700 escalones, es ideal para quienes buscan aventura y desean conocer la atracción desde nuevas perspectivas. Pensando en el esparcimiento familiar y en la tradición del asado gaúcho, el área de <strong>asadores</strong> también será revitalizada, pasando de tres quioscos a diez. Con una estructura moderna y más cómoda, podrás reunir a tus amigos y familia para un delicioso asado en medio de la tranquilidad del parque.</p>
                <p>Para los aventureros, te presentamos el <strong>Salto de Péndulo</strong>: una caída libre de 100 metros. ¡Es pura adrenalina para quienes buscan emociones extremas en la naturaleza! Durante las obras para la construcción del nuevo Parque do Caracol, que ofrecerá diversas atracciones inolvidables, el parque estará temporalmente cerrado para garantizar la seguridad de los visitantes y colaboradores.</p>
                <p><strong>Mantente atento</strong> a las novedades en nuestro Instagram oficial (<a href="https://www.instagram.com/parquedocaracol">@parquedocaracol</a>) para seguir el proceso de transformación del parque.</p>
                {/* <p>
                    El Parque do Caracol es un destino imperdible para quienes buscan aventura, diversión y momentos inolvidables en medio de la naturaleza de la Serra Gaúcha. Ubicado en Canela - RS, el parque ofrece una experiencia completa para toda la familia. Su principal atracción es un verdadero espectáculo de la naturaleza: la Cascada del Caracol, con una caída libre de 131 metros, que puede ser apreciada en el <strong>mirador</strong>, donde hay una vista privilegiada.
                </p>
                <p>
                    Además del atractivo principal - <strong>la Cascada del Caracol</strong> -, el Parque también ofrece un recorrido completo con <strong>otras atracciones imperdibles</strong> para explorar, empezando por los <strong>senderos ecológicos</strong>, que ofrecen una experiencia increíble en medio de la rica fauna y flora local.
                </p>
                <p>
                    Otra atracción perfecta para todos los tipos de público es el <strong>área de picnic</strong>, ideal para disfrutar con la familia o amigos. También es posible aprovechar el <strong>parque infantil</strong> con los niños con diversas opciones de juguetes, incluyendo resbaladillas, columpios y una pequeña tirolesa que divierte a niños y adultos. Nuestro espacio es inclusivo y tiene juguetes adaptados para personas con necesidades especiales.
                </p>
                <p>
                    Para aquellos que les gusta relajarse y contemplar la naturaleza, encontrarán a su disposición un <strong>área de hamacas</strong> para tomarse un descanso en la rutina diaria. Para celebrar, hay un <strong>área de parrillas</strong> para reunir a personas especiales. También tenemos un <strong>restaurante</strong> para saborear comida típica, casera y hecha en estufa de leña, con música en vivo los fines de semana y diversas tiendas para garantizar regalos.
                </p>
                <p>
                    También podrá comprar boletos para subir al <strong>Observatorio Panorámico</strong>, que se encuentra a una altura de 27 m, con una vista de 360º del parque, para admirar la cascada en la copa de los árboles. Y para el paseo en trenecito por la <strong>Estación Sonho Vivo</strong>, con 800 m de extensión, para viajar en el tiempo y descubrir un poco de la historia de la colonización de la región.
                </p>
                <p>
                    Viva todo esto junto a compañías especiales, en la postal de la Serra Gaúcha. Disfrute el día en el Parque do Caracol en un viaje a la cascada.
                </p>
                <p>
                    Siga el Instagram oficial (<a href="https://www.instagram.com/parquedocaracol">@parquedocaracol</a>) para mantenerse al tanto de la programación del parque.
                </p>
                <p>
                    <strong>Atención:</strong> <br />
                    Para disfrutar del ambiente de forma tranquila, evite salir de los senderos y observe la señalización; deseche sus residuos en los lugares indicados y no alimente a los animales silvestres.
                </p>
                <p>
                    Otras reglas importantes:
                    <ul>
                        <li>No recoja plantas, animales y minerales;</li>
                        <li>Evite fumar en el parque;</li>
                        <li>Prohibido hacer fogatas;</li>
                        <li>No se bañe ni beba el agua del Arroyo Caracol.</li>
                    </ul>
                </p> */}
            </div>
        </div>
    )
}

export default TheParkES;